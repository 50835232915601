import { CampoBusca, FooterPrincipal, MenuPrincipal } from "../../shared/components";
import { LayoutBase } from "../../shared/layout";
import { ContentSobre } from "./components/ContentSobre";

export const Sobre = () => {

    return (
        <>
            <CampoBusca/>
            <MenuPrincipal/>

            <LayoutBase title="Sobre - AlexsandroCS">
                <ContentSobre/>
            </LayoutBase>
            
            <FooterPrincipal/>
        </>
    );
}