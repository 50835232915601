import { ExceptionError } from "../APIErrorConfig"
import { API } from "../APIAxiosConfig"

export interface ILoginTokenAccess{
    isValid: boolean;
    idUserValid: number;
    userValid: string;
}

// Fazendo login no site.
const postLogin = async (email: string, password: string): Promise<string | ExceptionError> => {
    try {
        const urlRelative = `/login`;
        const { data } = await API().post(urlRelative,{email, password});
        return data.token;
    }
    catch (error: any) {
        return new ExceptionError(error.msg || "Tentativa de login inválida, E-mail ou Senha errada!");
    }
}

// Confirmando token.
const tokenConfirm = async (verificaToken: string): Promise<ILoginTokenAccess | ExceptionError> => {
    try {
        const urlRelative = `/validate`;
        const {data} = await API().post(urlRelative,{verificaToken});
        return data;
    }
    catch (error: any) {
        return new ExceptionError(error.msg || "O Token é inválido!");
    }
}

export const EndPointUser = {
    postLogin,
    tokenConfirm
}