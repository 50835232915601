import { createTheme } from "@mui/material";

// Estendendo a interface Palette para incluir 'tertiary'
declare module '@mui/material/styles/createPalette' {
    interface Palette {
      tertiary: Palette['primary'];
    }

    interface PaletteOptions {
      tertiary?: PaletteOptions['primary'];
    }
}

export const LightMode = createTheme({
    palette: {
        primary:{
            main: "#fc6005",
            dark: "#f09662",
            light: "#ffb74d",
            contrastText: "#fff",
        },
        secondary:{
            main: "#fc6005",
            dark: "#3a4553",
            light: "#ffb74d",
            contrastText: "#000"
        },
        tertiary:{ // Cor de efeito hover: branco e laranja
            main: "#e1ab91",
            dark: "#e64d02",
            light: "#dfdfdf",
            contrastText: "#fff"

        },
        background:{
            default: "#f4f0e9",
            paper: "#d0d0d0",
        },
        text:{
            primary: "#000",
            secondary: "#464646"
        }
    },
    breakpoints:{
        values:{
            xs: 0,
            sm: 520,
            md: 900,
            lg: 1200,
            xl: 1536,
        }
    }
});