import { Box, Divider, styled, Typography } from '@mui/material';

export const FooterPrincipal = () => {

    // Final da página.
    const BoxFooterPrincipal = styled(Box)(({theme}) => ({
        width: "100%",
        borderTopColor: `${theme.palette.tertiary.dark} !important`,
        background: `linear-gradient(-150deg, ${theme.palette.tertiary.dark} 20%, #3a4655 60%, #373737 110%)`,
        [theme.breakpoints.down("md")]:{
            flexDirection: "column",
            alignItems: "center"
        }
    }));

    // Box principal de informações finais.
    const FinalSite = styled(Box)(({theme}) => ({
        display: "flex",
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap"
    }));

    // Box esquerda e direita com informações finais.
    const BoxFinalSite = styled(Box)(({theme}) => ({
        flexGrow: 2,
        textAlign: "center",
        margin: "1.5%",
        color: `${theme.palette.tertiary.contrastText}`,
        [theme.breakpoints.down("md")]:{
            flexDirection: "column",
            alignItems: "center"
        }
    }));

    // Box principal dos links e contatos.
    const BoxLinksFinalSite = styled(Box)(({theme}) => ({
        display: "flex",
        paddingBottom: "2%",
        flexDirection: "row",
        justifyContent: "center",
        flex: 1,
        textAlign: "center",
        [theme.breakpoints.down(400)]:{
            flexDirection: "column",
        }
    }));

    // Links das redes sociais e contatos.
    const LinkPostagem = styled("a")(({theme}) => ({
        margin: "2%",
        cursor: "pointer",
        color: `${theme.palette.tertiary.contrastText}`,
        textDecoration: "none",
        fontSize:"clamp(0.8rem, 1vw + 0.8rem, 0.9rem)",
        fontWeight: "900",
        fontFamily: "Alata, sans-serif",
        "&:hover":{
            textDecoration: "underline"
        }
    }));

    return(
        <>
            <BoxFooterPrincipal borderTop={3}>
                <FinalSite>
                    <BoxFinalSite sx={{display: "flex", flexDirection: "row", alignItems: "top"}}>
                        <Typography component="p" variant="subtitle1" fontSize="clamp(0.7rem, 1vw + 0.7rem, 0.9rem)" sx={{fontWeight: "bolder", fontFamily: "Alata, sans-serif", marginRight: 1}}>Criado por: AlexsandroCS - 2024</Typography>
                        {/* <Typography component="span" variant="subtitle1" fontSize="clamp(0.7rem, 1vw + 0.7rem, 0.9rem)" sx={{fontWeight: "bolder", fontFamily: "Alata, sans-serif"}}></Typography> */}
                    </BoxFinalSite>
                    <BoxFinalSite sx={{}}>
                        <Typography component="p" variant="subtitle1" fontSize="clamp(0.7rem, 1vw + 0.7rem, 0.9rem)" sx={{fontWeight: "bolder"}}><i className="fa-solid fa-envelope"></i> E-mail: <Typography component="span" variant="subtitle1" fontSize="clamp(0.7rem, 1vw + 0.7rem, 0.9rem)" fontFamily={"Alata, sans-serif"}>Alexsandro.acs98@gmail.com</Typography></Typography>
                        <Typography component="p" variant="subtitle1" fontSize="clamp(0.7rem, 1vw + 0.7rem, 0.9rem)" sx={{fontWeight: "bolder"}}><i className="fa-brands fa-whatsapp"></i> Whatsapp: <Typography component="span" variant="subtitle1" fontSize="clamp(0.7rem, 1vw + 0.7rem, 0.9rem)" fontFamily={"Alata, sans-serif"}>(11) 93436-3210</Typography></Typography>
                    </BoxFinalSite>
                </FinalSite>

                <Divider sx={{borderBottomWidth: "thick"}}/>

                <Typography sx={{textAlign: "center", fontWeight: "900", fontFamily: "Alata, sans-serif", marginTop: "0.5%"}}>Redes e Contatos</Typography>

                <BoxLinksFinalSite>
                    <LinkPostagem href="https://www.linkedin.com/in/alexsandrocs"><i className="fa-brands fa-linkedin"></i> Linkedin</LinkPostagem>
                    <LinkPostagem href="https://www.alexsandrocs.com"><i className="fa-solid fa-file-pdf"></i> Currículo</LinkPostagem>
                    <LinkPostagem href="https://www.youtube.com/@AlexsandroCS"><i className="fa-brands fa-youtube"></i> Youtube</LinkPostagem>
                    <LinkPostagem href="https://github.com/AlexsandroCS"><i className="fa-brands fa-github"></i> Github</LinkPostagem>
                    <LinkPostagem href="https://wa.me/5511934363210"><i className="fa-brands fa-whatsapp"></i> Whatsapp</LinkPostagem>
                </BoxLinksFinalSite>
            </BoxFooterPrincipal>
        </>
    );
}