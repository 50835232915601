import { ExceptionError } from "../APIErrorConfig";
import { API } from "../APIAxiosConfig";

export interface IListPost{
    id: number | null;
    nameCreator: string;
    title: string;
    content: string;
    tag: string;
    imageLink: string;
    link: string;
    date: Date;
}

export interface IPostPagination{
    post: IListPost[];
    totalPosts: number;
    totalPages: number;
}

// Capturando últimos 10 posts e inserindo na página inicial. 
const getPosts = async (page = 0): Promise<IPostPagination | ExceptionError> => {
    try {
        const urlRelative = `/post?page=${page}&size=10`;
        const { data } = await API().get(urlRelative);

        return data;
    }
    catch (error: any) {
        return new ExceptionError(error.msg || "Nenhuma postagem foi encontrada!");
    }
}

// Capturando posts buscado pelo usuário no campo de pesquisa.
const getSearchPosts = async (buscaInfo: string = ""): Promise<IListPost[] | ExceptionError> => {
    try {
        const urlRelative = `/post/${buscaInfo}`;
        const { data } = await API().get(urlRelative);

        return data;
    }
    catch (error: any) {
        return new ExceptionError(error.msg || "Nenhuma postagem foi encontrada na busca!");
    }
}

// Capturando post específicado pelo id.
const getPostId = async (buscaPost: number): Promise<IListPost | ExceptionError> => {
    try {
        const urlRelative = `/postagem_id/${buscaPost}`;
        const { data } = await API().get(urlRelative);

        return data;
    }
    catch (error: any) {
        return new ExceptionError(error.msg || "Postagem não foi encontrada!");
    }
}

// Capturando post específicado pelo nome.
const getPostName = async (buscaPost: string): Promise<IListPost | ExceptionError> => {
    try {
        const urlRelative = `/postagem_name/${buscaPost}`;
        const { data } = await API().get(urlRelative);

        return data;
    }
    catch (error: any) {
        return new ExceptionError(error.msg || "Postagem não foi encontrada!");
    }
}

// Capturando os ultimos 10 posts de um id específico.
const getPostsCreator = async (nameUser: string, page = 0): Promise<IPostPagination | ExceptionError> => {
    try {
        const urlRelative = `/post_criador/${nameUser}?page=${page}&size=10`;
        const { data } = await API().get(urlRelative);

        return data;
    }
    catch (error: any) {
        return new ExceptionError(error.msg || "Nenhuma postagem foi encontrada!");
    }
}

// Criando novo post.
const postPostagem = async (criandoPostagem: Omit<IListPost, "id" | "date">) => {
    const urlRelative = `post`;
    
    try {
        const response = await API().post<IListPost>(urlRelative, criandoPostagem, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}` // Adicionando o Bearer Token no cabeçalho
            }
        });

        if (response.status === 200) {
            return {message: "Postagem enviada com sucesso!", success: true};
        }
        else {
            return {message: "Postagem não foi enviada devido a um erro!", success: false};
        }
    }
    catch (error) {
        return {message: "Ocorreu um erro na requisição!", success: false};
    }
};

// Editando uma postagem.
const postEditPostagem = async (editandoPostagem: Omit<IListPost, "date">) => {
    const urlRelative = `post/${editandoPostagem.id}`;

    try {
        const response = await API().put<IListPost>(urlRelative, editandoPostagem, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });

        if (response.status === 200) {
            return {message: "Postagem enviada com sucesso!", success: true};
        }
        else {
            return {message: "Postagem não foi enviada devido a um erro!", success: false};
        }
    }
    catch (error) {
        return {message: "Ocorreu um erro na requisição!", success: false};
    }
}

// Excluíndo uma postagem.
const postDeletePostagem = async (idPostagem: number) => {
    const urlRelative = `post/${idPostagem}`;

    try {
        const response = await API().delete(urlRelative, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });

        if (response.status === 200) {
            return {message: "Postagem deletada com sucesso!", success: true};
        }
        else {
            return {message: "Não foi possível fazer a exclusão dessa postagem!", success: false};
        }
    }
    catch (error) {
        return {message: "Ocorreu um erro na requisição!", success: false};
    }
}

export const EndPointPostagem = {
    getPosts,
    getPostId,
    getPostName,
    getSearchPosts,
    getPostsCreator,
    postPostagem,
    postEditPostagem,
    postDeletePostagem
}