import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from "@mui/material";
import { useState } from "react";
import { BoxMessageErro } from "./BoxMessageErro";
import { useConfirmErrorContext } from "../contexts/ConfirmErrorContext";

interface IDeleteDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void; // Função que será chamada quando o usuário confirmar a exclusão.
}

export const DeleteDialog: React.FC<IDeleteDialogProps> = ({ open, onClose, onConfirm }) => {

    const [inputValue, setInputValue] = useState("");
    const {openError, handleOpenError, handleCloseError} = useConfirmErrorContext();
    const [messageErro, setMessageErro] = useState({title: "", content:""});

    const handleConfirm = () => {

        if (inputValue.toLowerCase() === "excluir") {
            onConfirm();
        }
        else {
            handleOpenError();
            setMessageErro({title:"Erro na confirmação", content:"Você precisa digitar o texto correto para excluir a postagem!"});
        }
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Confirmar Exclusão</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Para excluir esta postagem, por favor digite "excluir" no campo abaixo.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Digite: excluir"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onClose}>Cancelar</Button>
                    <Button variant="contained" onClick={handleConfirm} color="error">Confirmar</Button>
                </DialogActions>
            </Dialog>

            {
                messageErro.title && (<BoxMessageErro openBox={openError} titleError={messageErro.title} contentError={messageErro.content} closeBox={handleCloseError}/>)
            }
        </>
    );
};
