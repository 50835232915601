import { useState } from "react"

export const useConfirmErrorContext = () => {

    const [openError, setOpenError] = useState(true);

    const handleOpenError = () => {
        setOpenError(true);
    }

    const handleCloseError = () => {
        setOpenError(false);
    }
    return{
        openError,
        handleOpenError,
        handleCloseError
    };
}