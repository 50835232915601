import { Card, Box, Avatar, Typography, styled, ImageList, ImageListItem } from '@mui/material';
import image from "../../../shared/images/P_20240822_133831_BF.jpg";
export const ContentSobre = () => {

    const SobreCard = styled(Card)(({theme}) => ({
        background: `linear-gradient(to right, ${theme.palette.tertiary.light} 0%, ${theme.palette.tertiary.light} 0%, ${theme.palette.tertiary.light} 20%, ${theme.palette.tertiary.dark} 160%)`,
        [theme.breakpoints.down(700)]: {
            background: `linear-gradient(to bottom, ${theme.palette.tertiary.light} 0%, ${theme.palette.tertiary.light} 0%, ${theme.palette.tertiary.light} 20%, ${theme.palette.tertiary.dark} 160%)`,
        },
    }));

    const AvatarSobre = styled(Avatar)(({theme}) => ({
        width: 224,
        height: 224,
        transition: "background 0.5s ease-in-out",
        "&:hover": {
            backgroundPosition: "right bottom",
        },
        [theme.breakpoints.down(900)]: {
            width: 184,
            height: 184,
        },
    }));

    const TextApresentation = styled(Typography)(({theme}) => ({
        width: "50%",
        fontSize: "100%",
        fontFamily: "Alata, sans-serif",
        [theme.breakpoints.down(1400)]: {
            width: "70%"
        },
        [theme.breakpoints.down(1200)]: {
            width: "90%"
        },
        [theme.breakpoints.down(1000)]: {
            width: "100%"
        },
        [theme.breakpoints.down(875)]: {
            fontSize: "clamp(0.8rem, 1vw + 0.5rem, 0.9rem)"
        },
    }));

    const itemData= [
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/java/java-original-wordmark.svg",
            title: "Java"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/spring/spring-original-wordmark.svg",
            title: "Spring Boot"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg",
            title: "JavaScript"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/typescript/typescript-original.svg",
            title: "TypeSript"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-original-wordmark.svg",
            title: "HTML"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-original-wordmark.svg",
            title: "CSS"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/materialui/materialui-original.svg",
            title: "Material UI"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original-wordmark.svg",
            title: "React"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/angular/angular-original-wordmark.svg",
            title: "Angular"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original-wordmark.svg",
            title: "MySQL"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/docker/docker-original-wordmark.svg",
            title: "Docker"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original-wordmark.svg",
            title: "Git e GitHub"
        },
        {
            img: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/linux/linux-original.svg",
            title: "Linux"
        }
    ];

    return (
        <>
            <SobreCard sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", "@media (max-width: 700px)":{flexDirection: "column"}, marginBottom: "3%"}}>
                <Box m={1} border={3} borderRadius={50} borderColor={"tertiary.dark"}>
                    <AvatarSobre alt="AlexsandroCS foto" src={image} />
                </Box>

                <Box p={2} display={"grid"}>
                    <Typography variant="button" bgcolor={"tertiary.dark"} p={1} m={0.5} color={"#fff"} borderRadius={3} justifySelf={"start"}>Sobre mim</Typography>
                    <TextApresentation variant="body2" gutterBottom p={1}>
                        Meu nome é Alexsandro da Conceição Soares, tenho 26 anos, moro em São Paulo - SP. Sou um desenvolvedor focado em sistemas web.
                        Comecei na programação web em 2016 criando websites responsivos, escaláveis e com UX bem desenvolvido. Sou uma pessoa que gosta
                        de segurança de dados e de arquitetura de Software. Atualmente estou cursando ADS e estou no 5° Período. Estou sempre buscando 
                        conhecimento tec para me tornar um desenvolvedor cada dia melhor.
                    </TextApresentation>
                </Box>
            </SobreCard>

            <SobreCard sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start", "@media (max-width: 700px)":{flexDirection: "column"}, marginBottom: "3%"}}>
                <Box p={2} display={"grid"}>
                    <Typography variant="button" bgcolor={"tertiary.dark"} p={1} m={0.5} mb={3} color={"#fff"} borderRadius={3} justifySelf={"start"}>Redes Sociais</Typography>
                    
                    <Box sx={{display: "flex", listStyle: "none", columnGap: 2, "@media (max-width: 700px)":{columnGap: "4px"}}}>
                        <a href="https://www.linkedin.com/in/alexsandrocs/">
                            <Avatar src="/icons/linkedin.png" alt="Perfil do AlexsandroCS no linkedin" sx={{width: "90%", cursor: "pointer", margin: "3px", "@media (max-width: 700px)":{width: "auto", height: "32px", margin: "auto"}}}/>
                        </a>

                        <a href="/">
                            <Avatar src="/icons/instagram.png" alt="Perfil do AlexsandroCS no instagram" sx={{width: "90%", cursor: "pointer", margin: "3px", "@media (max-width: 700px)":{width: "auto", height: "32px", margin: "auto"}}}/>
                        </a>

                        <a href="http://www.youtube.com/@AlexsandroCS">
                            <Avatar src="/icons/youtube.png" alt="Canal do AlexsandroCS no youtube" sx={{width: "90%", cursor: "pointer", margin: "3px", "@media (max-width: 700px)":{width: "auto", height: "32px", margin: "auto"}}}/>
                        </a>

                        <a href="https://github.com/AlexsandroCS">
                            <Avatar src="/icons/github.png" alt="Perfil do AlexsandroCS no github" sx={{width: "90%", cursor: "pointer", margin: "3px", "@media (max-width: 700px)":{width: "auto", height: "32px", margin: "auto"}}}/>
                        </a>

                        <a href="https://wa.me/5511934363210">
                            <Avatar src="/icons/whatsapp.png" alt="Chat direto do AlexsandroCS no whatsapp" sx={{width: "90%", cursor: "pointer", margin: "3px", "@media (max-width: 700px)":{width: "auto", height: "32px", margin: "auto"}}}/>
                        </a>

                        <a href="/" target="_blank">
                            <Avatar src="/icons/impressora.png" alt="Currículo atualizado do AlexsandroCS em PDF" sx={{width: "90%", cursor: "pointer", margin: "3px", "@media (max-width: 700px)":{width: "auto", height: "32px", margin: "auto"}}}/>
                        </a>
                    </Box>

                    <Typography variant="caption" p={1} m={0.5} mb={1} justifySelf={"start"}>Clique no ícone para ir ao perfil da rede social de AlexsandroCS.</Typography>
                </Box>
            </SobreCard>

            <SobreCard sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", "@media (max-width: 700px)":{flexDirection: "column"}}}>
                <Box p={2} display={"grid"}>
                    <Typography variant="button" bgcolor={"tertiary.dark"} p={1} m={0.5} color={"#fff"} borderRadius={3} justifySelf={"end"}>Habilidades</Typography>
                    <TextApresentation variant="body2" gutterBottom p={1} justifySelf={"end"} textAlign={"end"}>
                        Tenho habilidades com diversas ferramentas, linguagens de programação e com frameworks como: Java, Spring Boot, C# Forms, JavaScript, TypeScript, HTML, CSS,
                        Material UI, Angular, React, MySQL, Docker, Git e GitHub, Linux e Windows e Manutenção de Hardware.
                    </TextApresentation>
                </Box>

                <Box p={2} >
                    <ImageList sx={{ width: 350, height: 230, "@media (max-width: 1100px)":{width: 300},"@media (max-width: 400px)":{width: 240, height: "auto"}}} cols={5} rowHeight={45}>
                        {itemData.map((item) => (
                            <ImageListItem  key={item.img}>
                            <img
                                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                alt={item.title}
                                loading="lazy"
                            />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            </SobreCard>
        </>
    );
}