import { styled, Card, Typography, Box, Divider, useTheme, Avatar } from '@mui/material';
import React from 'react';
import { trataData } from '../../../shared/utils';

import image from "../../../shared/images/P_20240822_133831_BF.jpg";

interface IContentPostagem{
    title: string;
    content: string;
    date: Date;
    nameCreator: string
}

export const ContentPostagem: React.FC<IContentPostagem> = ({title, content, date, nameCreator}) => {

    const AvatarPostagem = styled(Avatar)(({theme}) => ({
        width: 48,
        height: 48,
        cursor: "pointer",
        transition: "opacity 1s, border 2.5s",
        "&:hover": {
            backgroundPosition: "right bottom",
            opacity: 0.6
        }
    }));

    const theme = useTheme();

    const BoxPostagem = styled(Box)(({theme}) => ({
        padding: "2% 3%"
    }));

    return(
        <>
            <Card>
                <BoxPostagem>
                    <Typography className="TypoSubtitle" width={"65%"} fontFamily={"Source Serif 4, serif"} fontSize={"clamp(0.7rem, 0.55vw + 0.9rem, 1.4rem)"} color={theme.palette.text.secondary} sx={{"@media (max-width: 900px)":{width:"100%"}}}>
                        {/* Estruture seu projeto Angular desta forma e deixe eu projeto escalável, limpo e de fácil entendimento para qualquer desenvolvedor */}
                        {title}
                    </Typography>
                </BoxPostagem>

                <Divider sx={{width: "94%", margin: "0 auto", background:`${theme.palette.secondary.main}`}}/>

                <BoxPostagem sx={{paddingTop: 1}}>
                    <Box display={"flex"} alignItems={"center"} p={1}>
                        <AvatarPostagem src={image} sx={{marginRight: 1, border:1, borderRadius:50, borderColor:`${theme.palette.secondary.main}`}}/>
                        <Typography fontSize={"clamp(0.7rem, 0.55vw + 0.7rem, 1.1rem)"} fontWeight={"bold"} sx={{cursor: "pointer"}}>{nameCreator}</Typography>
                    </Box>
                    <Typography variant="body2">Postado: {trataData(date)} </Typography>
                </BoxPostagem>
            </Card>

            <Card sx={{margin: "2% 0"}}>
                <BoxPostagem>
                    <Typography dangerouslySetInnerHTML={{__html: content}} fontFamily={"Merriweather, serif"} fontWeight={300} fontSize={"clamp(0.7rem, 0.5vw + 0.65rem, 1.15rem)"} color={theme.palette.text.primary} />
                </BoxPostagem>
            </Card>
        </>
    );
}