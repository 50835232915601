import { Box, styled } from "@mui/material";

interface ILayoutBase{
    title: string | undefined;
    children: React.ReactNode;
}

export const LayoutBase: React.FC<ILayoutBase> = ({title, children}) => {

    // Layout padrão das páginas.
    const LayoutBase = styled(Box)(({theme}) => ({
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingTop: "2%",
    }));

    // Título da página principal que estiver executando.
    const TitleLayoutBase = styled(Box)(({theme}) => ({
        background: "linear-gradient(-50deg, #1e1e1e 30%, #3a4655 60%, #373737 110%)",
        color: "#fff",
    }));

    // Conteudo padrão das páginas.
    const ConteudoLayoutBase = styled(Box)(({theme}) => ({
        height: "100%",
        padding: "2%",
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        width: "80%",
        [theme.breakpoints.down(900)]:{
            width: "90%"
        },
        [theme.breakpoints.down(700)]:{
            height: "auto"
        },
    }));

    const TituloConteudoPrincipal = styled("h1")(({theme}) => ({
        fontFamily: "Alata,sans-serif",
        fontSize: "clamp(1.4rem, 1vw + 1rem, 3rem)",
        fontWeight: "bolder",
        marginLeft: "10%",
        [theme.breakpoints.down(900)]:{
            marginLeft: "5%"
        },
    }));

    return(
        <LayoutBase>
            <TitleLayoutBase>
                <TituloConteudoPrincipal>{title}</TituloConteudoPrincipal>
            </TitleLayoutBase>
            <ConteudoLayoutBase>{children}</ConteudoLayoutBase>
        </LayoutBase>
    );
}