import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Inicio, Login, Sobre, Postagem, Administracao } from "../pages/index";

export const Rotas = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/inicio/:pg?" element={<Inicio />} />
                <Route path="/sobre" element={<Sobre />} />
                <Route path="/postagem/:urlPage?" element={<Postagem />} /> 
                <Route path="/login" element={<Login />} />
                <Route path="/administracao/*" element={<Administracao />} />
                <Route path="*" element={<Navigate to={"/inicio"} />} />
            </Routes>
        </BrowserRouter>
    );
};
