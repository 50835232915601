import { CampoBusca, FooterPrincipal, MenuPrincipal } from "../../shared/components";
import { LayoutBase } from "../../shared/layout";
import { ContentLogin } from "./components/ContentLogin";

export const Login = () => {

    return(
        <>
            <CampoBusca/>
            <MenuPrincipal/>

            <LayoutBase title="Login">
                <ContentLogin/>
            </LayoutBase>

            <FooterPrincipal/>
        </>
    );
}