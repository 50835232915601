import { Icon, IconButton } from "@mui/material";
import styled from "styled-components";
import { useAppThemeContext } from "../../contexts/SwitchThemeContext";
import { useSearch } from "../../contexts/OpenAndCloseSearchContext";
import React from "react";
import { useVerifyTokenUserLogado } from "../../contexts/ConfirmTokenUserContext";

export const MenuOpcoes: React.FC = () => {
    const {abrirPesquisa} = useSearch();
    const {toggleTheme, iconName} = useAppThemeContext();
    const {tokenIsValid, logout} = useVerifyTokenUserLogado();

    // Botão que troca cor do tema.
    const SwitchThemeButton = styled(Icon)(({theme}) => ({
        cursor: "pointer",
        color: "#fff",
    }));

    // Sair da conta.
    const fazerLogout = () => {
        logout();
        return window.location.reload();
    }

    return(
        <>
            <IconButton onClick={abrirPesquisa} sx={{margin:"1%", padding: "1%", "&:hover":{backgroundColor: "tertiary.dark"}}}>
                <SwitchThemeButton>search</SwitchThemeButton>
            </IconButton>
            <IconButton onClick={toggleTheme} sx={{margin:"1%", padding: "1%", "&:hover":{backgroundColor: "tertiary.dark"}}}>
                <SwitchThemeButton>{iconName}</SwitchThemeButton>
            </IconButton>

            {
                tokenIsValid ?
                (
                    <IconButton onClick={fazerLogout} sx={{margin:"1%", padding: "1%", "&:hover":{backgroundColor: "tertiary.dark"}}}>
                        <SwitchThemeButton>logout</SwitchThemeButton>
                    </IconButton>
                )
                :
                (null)
            }
        </>
    );
}