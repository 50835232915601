import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Card, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { ExceptionError } from "../../../shared/services";
import { EndPointUser } from "../../../shared/services/API/connection/User";
import { useNavigate } from "react-router-dom";
import { useVerifyTokenUserLogado } from "../../../shared/contexts/ConfirmTokenUserContext";
import { useConfirmErrorContext } from "../../../shared/contexts/ConfirmErrorContext";
import { BoxMessageErro } from "../../../shared/utils/BoxMessageErro";

export const ContentLogin = () => {

    const {tokenIsValid} = useVerifyTokenUserLogado();
    
    const theme = useTheme();
    const navigate = useNavigate();

    // Abrindo e fechando caixa de erro.
    const {openError, handleOpenError, handleCloseError} = useConfirmErrorContext();
    const [messageErro, setMessageErro] = useState({title: "", content:""});
    
    // Capturando Login e Senha.
    const [dadosLogin, setDadosLogin] = useState({email: "", senha:""});

    // Revelar e Esconder Senha.
    const [mostrarSenha, setMostrarSenha] = useState(false);
    const clickMostrarSenha = () => {
        setMostrarSenha((paramBoolean) => !paramBoolean) // Parâmetro é falso, mas com a negação ele transforma em true.
    };

    // Lembrar do E-mail e Senha.
    const [relembrarDados, setRelemebrarDados] = useState(false);
    const relembrarLogin = () => {
        setRelemebrarDados(!relembrarDados)
    };

    const fazerLogin = async () => {
        try {
            const response = await EndPointUser.postLogin(dadosLogin.email, dadosLogin.senha);

            if(response instanceof ExceptionError){
                handleOpenError();
                setMessageErro({title:"E-mail ou Senha inválida.", content:"Verifique se os dados estão corretos!"});
            }
            else{
                localStorage.setItem('email', dadosLogin.email);
                localStorage.setItem('senha', dadosLogin.senha);
                localStorage.setItem('token', response);
                
                window.location.reload();
                navigate("administracao/", {replace: true});
            }
        }
        catch (error: any) {
            handleOpenError();
            setMessageErro({title:"Ocorreu algum erro em nosso sistema.", content:"Tente mais tarde, ocorreu algum erro no sistema interno!"});
        }
    };

    if(tokenIsValid){
        return(
            <>
                {
                    navigate("/administracao/", { replace: true})
                }
            </>
        );
    }

    return(
        <>
            <Card sx={{m: "1% 30%", p: "4%", display: "flex", flexDirection: "column", rowGap: 4, "@media (max-width: 1150px)":{m: "1% 15%"}, "@media (max-width: 600px)":{m: "1%"}}}>
                <Typography variant="h6" sx={{padding: "2% 0% 8% 0%"}} color={theme.palette.secondary.contrastText}>Faça login na sua conta</Typography>

                {/* <Typography variant="caption" color={theme.palette.secondary.contrastText}>Ainda não tem uma conta?</Typography> */}
                <TextField 
                    id="outlined-search"
                    value={dadosLogin.email}
                    onChange={(modifyEmail) => setDadosLogin({...dadosLogin, email: modifyEmail.target.value})}
                    label="E-mail"
                    type="search"
                    sx={{width: "100%"}}
                />

                <TextField
                    id="inputPassword"
                    value={dadosLogin.senha}
                    onChange={(modifySenha) => setDadosLogin({...dadosLogin, senha: modifySenha.target.value})}
                    label="Senha"
                    type={mostrarSenha ? "text" : "password"}
                    InputProps={{
                        endAdornment:(
                            <InputAdornment position="end">
                                <IconButton aria-label="Mostrar senha" onClick={clickMostrarSenha} edge="end">
                                    {mostrarSenha ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    sx={{width: "100%"}}
                />

                <FormControlLabel control={
                    <Checkbox name="remember" onChange={relembrarLogin}/>
                } label="Lembrar-me"/>

                <Button variant="contained" sx={{p: 1.5, fontSize: "medium"}} onClick={fazerLogin}>Logar</Button>
            </Card>

            {
                messageErro.title && (<BoxMessageErro openBox={openError} titleError={messageErro.title} contentError={messageErro.content} closeBox={handleCloseError}/>)
            }
        </>
    );
}