import { Typography } from "@mui/material";
import { LayoutAdministration } from "../../../shared/layout";

export const PainelPrincipal = () => {

    return(
        <>
            <LayoutAdministration title="Início do Painel de Administração">
                <Typography>Painel Principal!</Typography>
            </LayoutAdministration>
        </>
    );
}