import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { Box, ThemeProvider } from "@mui/material";
import { DarkMode, LightMode } from "../themes";

interface IThemeContextData {
    themeName: "light" | "dark";
    iconName: "dark_mode" | "light_mode";
    toggleTheme: () => void;
}

interface IAppThemeProviderProps{
    children: React.ReactNode
}

const ThemeContext = createContext({} as IThemeContextData);

export const useAppThemeContext = () => {
    return useContext(ThemeContext);
}

export const AppThemeProvider: React.FC<IAppThemeProviderProps> = ({ children }) => {
    const [themeName, setThemeName] = useState<"light" | "dark">(() => {
        const savedTheme = localStorage.getItem("appTheme");
        return savedTheme as "light" | "dark" || "light";
    });
    
    const [iconName, setIconName] = useState<"light_mode" | "dark_mode">(() => {
        const savedIcon = localStorage.getItem("appIcon");
        return savedIcon as "light_mode" | "dark_mode" || "dark_mode";
    });

    const toggleTheme = useCallback(() => {
        setThemeName(oldThemeName => {
            const newTheme = oldThemeName === "light" ? "dark" : "light";
            localStorage.setItem("appTheme", newTheme);
            return newTheme;
        });
        setIconName(oldIconName => {
            const newIcon = oldIconName === "light_mode" ? "dark_mode" : "light_mode";
            localStorage.setItem("appIcon", newIcon);
            return newIcon;
        });
    }, []);

    const theme = useMemo(() => {
        return themeName === "light" ? LightMode : DarkMode;
    }, [themeName]);

    return (
        <ThemeContext.Provider value={{ themeName, iconName, toggleTheme }}>
            <ThemeProvider theme={theme}>
                <Box width={"100%"} height={"100%"} bgcolor={theme.palette.background.default}>
                    {children}
                </Box>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
}
