import { CampoBusca, FooterPrincipal, MenuPrincipal } from "../../shared/components";
import { Routes, Route, Navigate} from "react-router-dom";
import { PainelPrincipal, MinhasPostagens, TodasPostagens, EditarPerfil } from "./index";

import { useVerifyTokenUserLogado } from "../../shared/contexts/ConfirmTokenUserContext";
import { CriarEditarPost } from "./pages/CriarEditarPost";


export const Administracao = () => {

    const {tokenIsValid, tokenNameUser} = useVerifyTokenUserLogado();

    return (
        <>
            <CampoBusca />
            <MenuPrincipal />
            
            {
                tokenIsValid !== true ? (
                    <Navigate to={"/login"}/>
                )
                :
                (
                    <Routes>
                        <Route path="/" element={<PainelPrincipal />} />

                        {/* ROTAS - Minhas postagens.*/}
                        <Route path="/minhas-postagens" element={<MinhasPostagens nameUser={tokenNameUser}/>} />
                        <Route path="/minhas-postagens/:pg?" element={<MinhasPostagens nameUser={tokenNameUser}/>} />

                        {/* ROTAS - Todas as postagens. */}
                        <Route path="/todas-postagens" element={<TodasPostagens nameUser={tokenNameUser} />} />
                        <Route path="/todas-postagens/:pg?" element={<TodasPostagens nameUser={tokenNameUser} />} />

                        {/* ROTAS - Editar perfil. #Construir */}
                        <Route path="/editar-perfil" element={<EditarPerfil />} />

                        {/* ROTAS - Criar e Editar postagens.*/}
                        <Route path="/criar-post" element={<CriarEditarPost titlePage="Criar nova postagem" nameUser={tokenNameUser} />} />
                        <Route path="/editar-post/:id" element={<CriarEditarPost titlePage="Editar Postagem" nameUser={tokenNameUser} />} />
                    </Routes>
                )
            }

            <FooterPrincipal />
        </>
    );
};
