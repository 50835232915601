import { useEffect, useMemo, useState } from "react";
import { Box, Icon, IconButton, LinearProgress, Pagination, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { EndPointPostagem, IPostPagination } from "../../../shared/services/API/connection/Postagens";
import { ExceptionError } from "../../../shared/services/API/APIErrorConfig";
import { SnackbarTransform, trataData } from "../../../shared/utils";
import { LayoutAdministration } from "../../../shared/layout";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDeletePostContext } from "../../../shared/contexts/DeletePostContext";
import { DeleteDialog } from "../../../shared/utils/DeleteDialog";

export const MinhasPostagens = ({nameUser}: {nameUser: string}) => {

    // Link da postagem.
    const LinkPostagem = styled("a")(({theme}) => ({
        display: "inline-flex",
        justifyContent: "start",
        marginLeft: "2%",
        cursor: "pointer",
        color: `${theme.palette.text.primary}`,
        textDecoration: "none",
        fontSize:"clamp(0.8rem, 1vw + 0.8rem, 0.9rem)",
        fontWeight: "900",
        fontFamily: "Alata, sans-serif",
        "&:hover":{
            textDecoration: "underline"
        }
    }));

    const {openDialog, handleOpenDialog, handleCloseDialog, handleConfirmDelete, snackBarData} = useDeletePostContext();
    
    const navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(true);
    const [meusPost, setMeusPost] = useState<IPostPagination>({ post: [], totalPosts: 0, totalPages: 0 });

    const {pg} = useParams<{pg: string}>();

    const pageNumber = useMemo(() => {
        return Number(pg) ? Number(pg) - 1 : 0;
    },[pg]);

    useEffect(() => {
        setIsLoading(true);

        EndPointPostagem.getPostsCreator(nameUser, pageNumber).then((response) => {
            setIsLoading(false);
            if (response instanceof ExceptionError) {
                alert("Usuário não tem nenhum post até o momento!");
            }
            else {
                setMeusPost(response);
            }
        });
    },[nameUser, pageNumber]);

    return(
        <>
            <LayoutAdministration title="Minhas Postagens">
                {isLoading ? (<LinearProgress variant="indeterminate" />) : (null)}

                <TableContainer component={Paper} variant="outlined">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width={"50%"}>Postagem</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell align="center">Ações</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {meusPost.post.map((post_row) => (
                                <TableRow key={post_row.id}>
                                    <TableCell>{post_row.title}</TableCell>
                                    <TableCell>{trataData(post_row.date)}</TableCell>
                                    <TableCell align="center">
                                        {post_row.nameCreator === nameUser ? (
                                            <>
                                                <Link to={`../editar-post/${post_row.id}`}>
                                                    <IconButton color="info">
                                                        <Icon>construction</Icon>
                                                    </IconButton>
                                                </Link>

                                                <IconButton color="error" onClick={() => post_row.id && handleOpenDialog(post_row.id)}>
                                                    <Icon>delete_forever</Icon>
                                                </IconButton>
                                            </>
                                        ) : (null)}
                                    </TableCell>
                                    <TableCell align="right">
                                        <LinkPostagem href={"/postagem/"+post_row.link}>
                                            Ver postagem <Icon>arrow_right_alt</Icon>
                                        </LinkPostagem>    
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Box display={"flex"} m={3}>
                        {meusPost.totalPosts > 15 && (
                            <Pagination
                                sx={{ margin: "0 auto" }}
                                page={pageNumber + 1}
                                count={meusPost.totalPages}
                                variant="text"
                                shape="rounded"
                                color="primary"
                                onChange={(evt, pageNumber) => {
                                    navigate(`/administracao/minhas-postagens/${pageNumber}`); 
                                }}
                            />
                        )}
                    </Box>
                </TableContainer>

                {/* Exibe o DeleteDialog */}
                <DeleteDialog open={openDialog} onClose={handleCloseDialog} onConfirm={handleConfirmDelete} />

                {
                    snackBarData && (<SnackbarTransform msg={snackBarData.message} erro={!snackBarData.success} />)
                }

            </LayoutAdministration>
        </>
    );
}