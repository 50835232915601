import { AppBar, Box, Card, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, useTheme, styled, Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

const drawerWidth = 300;

const StyledLayoutAdministration = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "0% 0%",
}));

interface ILayoutAdministration {
    title: string;
    children: React.ReactNode;
}

export const LayoutAdministration: React.FC<ILayoutAdministration> = React.memo(({ title, children }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <StyledLayoutAdministration>
            <Card sx={{ display: "flex", flexDirection: "row", height: "100%" }}>

                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        position: "relative",
                        zIndex: 1,
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                    <List>
                        <ListItem disablePadding onClick={() => navigate("/administracao")}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Icon>home</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Início Painel" />
                        </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding onClick={() => navigate("/administracao/criar-post")}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Icon>post_add</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Criar nova postagem" />
                        </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding onClick={() => navigate("/administracao/minhas-postagens")}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Icon>save_as</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Minhas Postagens" />
                        </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding onClick={() => navigate("/administracao/todas-postagens")}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Icon>history_edu</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Todas Postagens" />
                        </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding onClick={() => navigate("/administracao/editar-perfil")}>
                        <ListItemButton>
                            <ListItemIcon>
                                <Icon>manage_accounts</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Editar Perfil" />
                        </ListItemButton>
                        </ListItem>
                    </List>
                </Drawer>

                <Box sx={{ width: "100%" }}>
                    <AppBar sx={{ position: "initial", background: `${theme.palette.primary.main}` }}>
                        <Toolbar>
                        <Typography variant="h6" noWrap component="div">
                            {title}
                        </Typography>
                        </Toolbar>
                    </AppBar>

                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        {children}
                    </Box>
                </Box>
            </Card>
        </StyledLayoutAdministration>
    );
});
