import { Box, Button, Card, Dialog, DialogActions, DialogContentText, DialogTitle } from "@mui/material";

export const BoxMessageErro = ({openBox, titleError, contentError, closeBox}: {openBox: boolean, titleError: string, contentError: string, closeBox: () => void}) => {

    return(
        <>
            <Card>
                <Dialog open={openBox} onClose={closeBox}>
                    <Box sx={{padding: 2}}>
                        <DialogTitle>{titleError}</DialogTitle>
                        <DialogContentText>
                            {contentError}
                        </DialogContentText>
                        <DialogActions>
                            <Button variant="contained" onClick={closeBox}>OK</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            </Card>
        </>
    );
}