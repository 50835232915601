import { useState } from 'react';
import { Snackbar, Alert, SnackbarCloseReason } from '@mui/material';

export const SnackbarTransform = ({ msg, erro }: { msg: string, erro: boolean }) => {
    const [openSnackbar, setOpenSnackbar] = useState(true);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    return (
        <Snackbar open={openSnackbar} autoHideDuration={7000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={erro ? "error" : "success"}>
                {msg}
            </Alert>
        </Snackbar>
    );
};
