import { createTheme } from '@mui/material';

export const DarkMode = createTheme({
    palette: {
        primary:{
            main: "#fc6005",
            dark: "#f09662",
            light: "#ffb74d",
            contrastText: "#fff",
        },
        secondary:{
            main: "#fc6005",
            dark: "#3a4553",
            light: "#ffb74d",
            contrastText: "#fff"
        },
        tertiary:{ // Cor de efeito hover: cinza e laranja
            main: "#414f62;",
            dark: "#1e4066",
            light: "#373839",
            contrastText: "#fff"
        },
        background:{
            default: "#282828",
            paper: "#313233",
        },
        text:{
            primary: "#fff",
            secondary: "#a4a4a4"
        }
    },
    breakpoints:{
        values:{
            xs: 0,
            sm: 520,
            md: 900,
            lg: 1200,
            xl: 1536,
        }
    }    
});