import { Typography } from "@mui/material";
import { LayoutAdministration } from "../../../shared/layout";

export const EditarPerfil = () => {

    return(
        <>
            <LayoutAdministration title="Editar Perfil">
                <Typography>Editar Perfil!</Typography>
            </LayoutAdministration>
        </>
    );
}