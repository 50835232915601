import { useState } from "react";
import { EndPointPostagem } from '../services/API/connection/Postagens';

export const useDeletePostContext = () => {

    const [openDialog, setOpenDialog] = useState(false);
    const [snackBarData, setSnackBarData] = useState<{message: string, success: boolean} | null>(null);
    const [postToDelete, setPostToDelete] = useState<number | null>(null);

    // Função para abrir o Dialog.
    const handleOpenDialog = (postId: number) => {
        setPostToDelete(postId);
        setOpenDialog(true);
    }

    // Função para fechar o Dialog.
    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    // Função para confirmar a exclusão da postagem.
    const handleConfirmDelete = async () => {
        if (postToDelete !== null) {
            const result = await EndPointPostagem.postDeletePostagem(postToDelete);
            setOpenDialog(false);
            setSnackBarData(result);
        }
    }

    // Passando todas funções do componente.
    return{
        openDialog,
        handleOpenDialog,
        handleCloseDialog,
        handleConfirmDelete,
        snackBarData
    };
}