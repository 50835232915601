import { CampoBusca, FooterPrincipal, MenuPrincipal } from "../../shared/components";
import { LayoutBase } from "../../shared/layout";
import { ContentPrincipal } from "./components/ContentPrincipal";

export const Inicio = () => {

    return(
        <>
            <CampoBusca/>
            <MenuPrincipal/>

            <LayoutBase title="Postagens">
                <ContentPrincipal/>
            </LayoutBase>

            <FooterPrincipal/>
        </>
    );
}
