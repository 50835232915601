
export const URLTransform = (urlOriginal: string):string => {
    return urlOriginal.normalize('NFD') // Normaliza o texto para decompor os acentos
        .replace(/[\u0300-\u036f]/g, '') // Remove os diacríticos (acentos)
        .replace(/Ç/g, 'C') // Substitui Ç maiúsculo
        .replace(/ç/g, 'c') // Substitui ç minúsculo
        .replace(/#/g, 'sharp') // Substitui # por "sharp"
        .replace(/ /g, '-') // Substitui espaços por "-"
        .replace(/[.,!]/g, '') // Remove pontos finais e vírgulas
        .toLowerCase();
}